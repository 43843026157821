
import { Vue, Component } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import UserModel from '@/models/UserModel';
import apiService from '@/services/apiService';
import LocationModel from '@/models/LocationModel';

@Component({ components: { VueApexCharts } })
export default class UserChartChart extends Vue {
  private allUsers: Array<UserModel> = [];
  private allLocations: Array<LocationModel> = [];

  private chartOptions = {
    labels: [] as string[] | null | undefined,
    chart: {
      type: 'pie',
      height: '200px',
      size: '100%',
      toolbar: {
        show: false,
      },
    },
    colors: ['#002D72', '#0097A9', '#A2B2C8', '#F6B21B ', '#6BCABA', '#5AB031'],
    dataLabels: {
      enabled: true,
      formatter: (val: number, opts: any) => {
        return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
      },
    },
    legend: {
      show: true,
      labels: {
        formatter: (seriesName: string, opts: any) => {
          return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
        },
      },
    },
  };

  private async created() {
    await this.getUsers();
    await this.getLocations();

    const updatedOptions = { ...this.chartOptions };
    updatedOptions.labels = this.locationLabels;
    updatedOptions.legend.labels.formatter = (val: any, opts: any) => {
      return this.locationLabels[opts.seriesIndex];
    };
    this.chartOptions = updatedOptions;
  }

  private async getUsers() {
    this.allUsers = await apiService.getAll<UserModel>('/user');
  }

  private async getLocations() {
    this.allLocations = await apiService.getAll<LocationModel>('/location');
  }

  private countUsersByLocation() {
    return this.allUsers.reduce((countByLocation: any, user: any) => {
      if (!countByLocation[user.locationId]) {
        countByLocation[user.locationId] = 0;
      }
      countByLocation[user.locationId]++;
      return countByLocation;
    }, {});
  }

  get locationLabels() {
    return this.allLocations.map((location: LocationModel) => location.name);
  }

  get chartSeries() {
    const userCountByLocation = this.countUsersByLocation();
    const totalUserCount = this.allUsers.length;

    return this.allLocations.map((location: any) => {
      const locationUserCount = userCountByLocation[location.locationId] || 0;
      return locationUserCount;
    });
  }
}
