
import { Vue, Component } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import UserModel from '@/models/UserModel';
import apiService from '@/services/apiService';
import DivisionModel from '@/models/DivisionModel';

@Component({ components: { VueApexCharts } })
export default class UserChartChart extends Vue {
  private allUsers: Array<UserModel> = [];
  private allDivisions: Array<DivisionModel> = [];

  private chartOptions = {
    labels: [] as string[] | null | undefined,
    chart: {
      type: 'pie',
      size: '100%',
      height: '200px',
      toolbar: {
        show: false,
      },
    },
    colors: ['#002D72', '#0097A9', '#A2B2C8', '#F6B21B ', '#6BCABA', '#5AB031'],
    dataLabels: {
      enabled: true,
      formatter: (val: number, opts: any) => {
        return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
      },
    },
    legend: {
      show: true,
      labels: {
        formatter: (seriesName: string, opts: any) => {
          return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
        },
      },
    },
  };

  private async created() {
    await this.getUsers();
    await this.getDivisions();

    const updatedOptions = { ...this.chartOptions };
    updatedOptions.labels = this.divisionLabels;
    updatedOptions.legend.labels.formatter = (val: any, opts: any) => {
      return this.divisionLabels[opts.seriesIndex];
    };
    this.chartOptions = updatedOptions;
  }

  private async getUsers() {
    this.allUsers = await apiService.getAll<UserModel>('/user');
  }

  private async getDivisions() {
    this.allDivisions = await apiService.getAll<DivisionModel>('/division');
  }

  private countUsersByDivision() {
    return this.allUsers.reduce((countByDivision: any, user: any) => {
      if (!countByDivision[user.divisionId]) {
        countByDivision[user.divisionId] = 0;
      }
      countByDivision[user.divisionId]++;
      return countByDivision;
    }, {});
  }

  get divisionLabels() {
    return this.allDivisions.map((division: DivisionModel) => division.name);
  }

  get chartSeries() {
    const userCountByDivision = this.countUsersByDivision();
    const totalUserCount = this.allUsers.length;

    return this.allDivisions.map((division: any) => {
      const divisionUserCount = userCountByDivision[division.divisionId] || 0;
      return divisionUserCount;
    });
  }
}
