
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import UserModel from '@/models/UserModel';
import DivisionModel from '@/models/DivisionModel';
import LocationModel from '@/models/LocationModel';
import SuffixModel from '@/models/SuffixModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UserDataTable extends Vue {
  @Ref() private readonly userForm!: VForm;
  @Ref() private readonly suffixForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private suffixIsEditing = false;
  private search = '';
  private suffixSearch = '';
  private rejectionReason = '';
  private dialog = false;
  private suffixDialog = false;
  private userModel: UserModel = new UserModel();
  private suffixModel: SuffixModel = new SuffixModel();
  private deleteUserDialog = false;
  private deleteSuffixDialog = false;
  private approveDialog = false;
  private rejectDialog = false;
  private allUsers: Array<UserModel> = [];
  private allDivisions: Array<DivisionModel> = [];
  private allLocations: Array<LocationModel> = [];
  private allSuffixes: Array<SuffixModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private roles = [
    {
      text: 'User',
      value: 'USR',
    },
    {
      text: 'Admin',
      value: 'ADM',
    },
  ];
  private headers = [
    { text: 'First Name', value: 'firstname', class: 'table-headers' },
    { text: 'Last Name', value: 'lastname', class: 'table-headers' },
    { text: 'Email address', value: 'emailAddress', class: 'table-headers' },
    { text: 'Division', value: 'divisionId', class: 'table-headers' },
    { text: 'Location', value: 'locationId', class: 'table-headers' },
    {
      text: ' Approval Status',
      value: 'activeStatus',
      class: 'table-headers',
      align: 'right',
    },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private suffixHeaders = [
    { text: 'Suffix', value: 'suffix', class: 'table-headers' },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private async created() {
    this.getSuffixes();
    this.getUsers();
    this.getLocations();
    this.getDivisions();
  }

  private async getUsers() {
    this.allUsers = await apiService.getAll<UserModel>('/user');
  }
  private async getLocations() {
    this.allLocations = await apiService.getAll<LocationModel>('/location');
  }
  private async getDivisions() {
    this.allDivisions = await apiService.getAll<DivisionModel>('/division');
  }
  private async getSuffixes() {
    this.allSuffixes = await apiService.getAll<SuffixModel>('/EmailSuffix');
  }

  private async refreshUsers() {
    this.getSuffixes();
    this.getUsers();
    this.getLocations();
    this.getDivisions();
  }

  private async saveUser() {
    if (!this.userForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    let suffix: any = this.allSuffixes.find((s) => {
      return this.userModel.emailSuffixId == s.emailSuffixId.toString();
    });
    this.userModel.emailAddress =
      this.userModel.emailAddress + '@' + suffix.suffix;
    if (this.isEditing) {
      try {
        let user = await apiService.put<UserModel>(
          '/user',
          this.userModel.userId,
          this.userModel
        );
        this.userModel = new UserModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('User edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/user', this.userModel).then(() => {
          return snackbarModule.snackSuccess('User added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private async saveSuffix() {
    if (!this.suffixForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.suffixIsEditing) {
      try {
        let suffix = await apiService.put<SuffixModel>(
          '/EmailSuffix',
          this.suffixModel.emailSuffixId,
          this.suffixModel
        );
        this.suffixModel = new SuffixModel();
        this.closeDialog();
        return snackbarModule.snackSuccess('Suffix edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/EmailSuffix', this.suffixModel).then(() => {
          return snackbarModule.snackSuccess('Suffix added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.closeDialog();
    }
  }
  private async editUser(item: any) {
    const [emailAddress, emailSuffix] = item.emailAddress.split('@');
    this.userModel = {
      ...item,
      emailAddress: emailAddress,
      emailSuffixId: this.allSuffixes.find(
        (suffix) => suffix.suffix === emailSuffix
      )?.emailSuffixId,
    };

    this.dialog = true;
    this.isEditing = true;
  }
  private async editSuffix(item: SuffixModel) {
    this.suffixModel = item;
    this.suffixDialog = true;
    this.suffixIsEditing = true;
  }
  private async approveUser(item: any) {
    this.userModel = item;
    this.approveDialog = true;
  }
  private async rejectUser(item: any) {
    this.userModel = item;
    this.rejectDialog = true;
  }
  private deleteUser(item: any) {
    this.userModel = item;
    this.deleteUserDialog = true;
  }
  private deleteSuffix(item: SuffixModel) {
    this.suffixModel = item;
    this.deleteSuffixDialog = true;
  }
  private async confirmDeleteUser() {
    let userName = this.userModel.firstname + ' ' + this.userModel.lastname;
    try {
      let user = await apiService.delete('/user', this.userModel.userId);
      this.closeDialog();
      return snackbarModule.snackSuccess(
        ` User ${userName} deleted successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async confirmDeleteSuffix() {
    try {
      await apiService.delete('/emailsuffix', this.suffixModel.emailSuffixId);
      this.closeDialog();
      return snackbarModule.snackSuccess(` Suffix deleted successfully`);
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async confirmApproveUser() {
    let userName = this.userModel.firstname + ' ' + this.userModel.lastname;
    this.userModel.isActive = true;
    try {
      let user = await apiService.putApproveUser(
        '/user/approveUser',
        this.userModel.userId,
        true
      );
      this.closeDialog();
      return snackbarModule.snackSuccess(
        ` User ${userName} approved successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async confirmRejectUser() {
    let userName = this.userModel.firstname + ' ' + this.userModel.lastname;
    this.userModel.isActive = true;
    try {
      let user = await apiService
        .putApproveUser(
          '/user/approveUser',
          this.userModel.userId,
          false,
          this.rejectionReason
        )
        .then(async () => {
          await apiService.delete('/user', this.userModel.userId);
        });
      this.closeDialog();
      return snackbarModule.snackSuccess(
        ` User ${userName} rejected successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async closeDialog() {
    this.userModel = new UserModel();
    this.suffixModel = new SuffixModel();
    this.isEditing = false;
    this.suffixIsEditing = false;
    this.deleteUserDialog = false;
    this.deleteSuffixDialog = false;
    this.approveDialog = false;
    this.rejectDialog = false;
    this.dialog = false;
    this.suffixDialog = false;
    this.rejectionReason = '';
    await this.refreshUsers();
  }
  private getDivisionName(item: UserModel) {
    let division = this.allDivisions.find((division) => {
      return division.divisionId === item.divisionId;
    });
    return division ? division.name : 'N/A';
  }
  private getLocationName(item: UserModel) {
    let location = this.allLocations.find((location) => {
      return location.locationId === item.locationId;
    });
    return location ? location.name : 'N/A';
  }
}
