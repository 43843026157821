import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Admin from '@/views/Admin.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/UserDashboard',
    name: 'UserDashboard',
    component: () => import('@/views/UserDashboard.vue'),
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { requiresAuth: true },
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/Folder/:id/:Admin',
    name: 'Folder',
    meta: { requiresAuth: true },
    component: () => import('@/views/FolderDetails.vue'),
  },
  {
    path: '/password-reset/:id',
    name: 'password-reset',
    props: true,
    component: () => import('@/views/ResetPassword.vue'),
  },
  {
    path: '/Admin',
    name: 'Admin',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: Admin,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  await store.dispatch('getUser').catch(() => {});
  const isAuthenticated: boolean = store.state.isLoggedIn;
  const isAdmin: boolean = store.state.user.role === 'ADM';
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (requiresAdmin && !isAdmin) {
    next('/');
  } else {
    next();
  }
});

export default router;
