
import { Vue, Component, Ref } from 'vue-property-decorator';
import { VForm } from '@/types/vForm';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '@/store/modules/snackbarModule';
import apiService from '@/services/apiService';
import FolderDataTable from '@/components/FolderDataTable.vue';
import FileDataTable from '@/components/FileDataTable.vue';
import UserDataTable from '@/components/UserDataTable.vue';
import LocationDataTable from '@/components/LocationDataTable.vue';
import TagNameDataTable from '@/components/TagNameDataTable.vue';
import DivisionDataTable from '@/components/DivisionDataTable.vue';
import FilesForApprovalDataTable from '@/components/FilesForApprovalDataTable.vue';
import UserDivisionChart from '@/components/UserDivisionChart.vue';
import UserLocationChart from '@/components/UserLocationChart.vue';
import FileForApprovalModel from '@/models/FileForApprovalModel';
import TagModel from '@/models/TagModel';
@Component({
  components: {
    FolderDataTable,
    FileDataTable,
    UserDataTable,
    LocationDataTable,
    TagNameDataTable,
    DivisionDataTable,
    FilesForApprovalDataTable,
    UserDivisionChart,
    UserLocationChart,
  },
})
export default class Admin extends Vue {
  private selected = 'e1';
  private isAnsaAdmin = false;
  private refreshProductKey: number = 0;
  private allTags: Array<TagModel> = [];
  private refreshUserKey: number = 0;
  private refreshCompaniesKey: number = 0;
  private allFilesForApproval: FileForApprovalModel[] = [];
  private async created() {
    await this.getUser();
    await this.getFilesForApproval();
  }

  private async getTags() {
    this.allTags = await apiService.getAll<TagModel>('/tag');
  }
  private async getUser() {
    this.isAnsaAdmin = this.user.role == 'ADM' ? true : false;
  }
  get user() {
    return { ...this.$store.state.user };
  }
  private async getFilesForApproval() {
    let response = await apiService.getAll<FileForApprovalModel>('/document');
    this.allFilesForApproval = response.filter((file) => {
      return file.folderId == null;
    });
  }
}
