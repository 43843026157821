
import { Vue, Component, Watch } from 'vue-property-decorator';
import UserModule from '@/store/modules/userModule';
import TabModule from '@/store/modules/tabModule';
import { getModule } from 'vuex-module-decorators';
import NavigationModel from '../../models/NavigationModel';
import store from '@/store/index';
import apiService from '@/services/apiService';
import UserModel from '@/models/UserModel';
const userModule = getModule(UserModule);
const tabModule = getModule(TabModule);

@Component({ components: {} })
export default class Navbar extends Vue {
  private firstname = '';
  private lastname = '';
  private logout(): void {
    userModule.logout();
  }
  private async mounted() {
    await this.getUser();
  }
  get user() {
    return this.$store.state.user;
  }
  get isAdmin() {
    return this.$store.getters.isAdmin;
  }
  private async getUser() {
    if (this.user && this.user.firstname && this.user.lastname) {
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
    } else {
      this.firstname = '';
      this.lastname = '';
    }
  }

  @Watch('user', { immediate: true, deep: true })
  onUserChanged(newVal: UserModel, oldVal: UserModel) {
    this.getUser();
  }
}
